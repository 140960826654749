import * as React from 'react';
import { cn } from 'utils/cn';
import { Icon, IconProps } from '../Icon/Icon';
import { TextLink, TextLinkProps } from '../Link/Link';
import { Text, TextProps } from '../Text/Text';

const Breadcrumb = React.forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<'nav'> & {
    separator?: React.ReactNode;
  }
>(({ ...props }, ref) => <nav ref={ref} aria-label="breadcrumb" {...props} />);
Breadcrumb.displayName = 'Breadcrumb';

const BreadcrumbList = React.forwardRef<HTMLOListElement, React.ComponentPropsWithoutRef<'ol'>>(
  ({ className, ...props }, ref) => (
    <ol ref={ref} className={cn('flex flex-wrap items-center gap-2 break-words', className)} {...props} />
  ),
);
BreadcrumbList.displayName = 'BreadcrumbList';

const BreadcrumbItem = React.forwardRef<HTMLLIElement, React.ComponentPropsWithoutRef<'li'>>(
  ({ className, ...props }, ref) => (
    <li ref={ref} className={cn('inline-flex items-center gap-1.5', className)} {...props} />
  ),
);
BreadcrumbItem.displayName = 'BreadcrumbItem';

const BreadcrumbBack = React.forwardRef<HTMLAnchorElement, TextLinkProps>(({ children, className, ...props }, ref) => (
  <TextLink className={cn('flex items-center gap-2 text-primary-30', className)} ref={ref} {...props}>
    <Icon className="size-3.5 rotate-180" icon={{ name: 'angle-right', styling: 'fal' }} />
    {children}
  </TextLink>
));
BreadcrumbBack.displayName = 'BreadcrumbBack';

const BreadcrumbLink = React.forwardRef<HTMLAnchorElement, TextLinkProps>((props, ref) => (
  <TextLink ref={ref} {...props} />
));
BreadcrumbLink.displayName = 'BreadcrumbLink';

const BreadcrumbLabel = React.forwardRef<HTMLSpanElement, TextProps>(({ className, ...props }, ref) => (
  <Text className={cn('font-bold', className)} ref={ref} {...props} />
));
BreadcrumbLabel.displayName = 'BreadcrumbLabel';

const BreadcrumbSeparator = ({
  children,
  className,
  icon = { name: 'angle-right', styling: 'fal' },
  ...props
}: React.ComponentProps<'li'> & { icon?: IconProps['icon'] }) => (
  <li role="presentation" aria-hidden="true" className={className} {...props}>
    {children ?? <Icon className="size-3.5" icon={icon} />}
  </li>
);
BreadcrumbSeparator.displayName = 'BreadcrumbSeparator';

const BreadcrumbEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
  <span
    role="presentation"
    aria-hidden="true"
    className={cn('flex h-9 w-9 items-center justify-center', className)}
    {...props}
  >
    <Text>...</Text>
    <span className="sr-only">More</span>
  </span>
);
BreadcrumbEllipsis.displayName = 'BreadcrumbElipssis';

export {
  Breadcrumb,
  BreadcrumbBack,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLabel,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
};
