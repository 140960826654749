import useRouter from 'hooks/useRouter';
import { useDispatch } from 'react-redux';
import { FACET_CODE } from '../constants/search';
import { applyFilter, applyInputFilter, checkIfCurrentPageHasQuery, updateUrlQuery } from '../utils/filterUtil';

export const useFilter = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  return {
    checkIfCurrentPageHasQuery: () => checkIfCurrentPageHasQuery(router),
    handleFilter: (searchObject: Record<string, string>, isConfiguration = false) =>
      applyFilter(dispatch, router, searchObject, isConfiguration),
    handleInputFilter: (
      baseQuery?: string,
      queryString?: string,
      filterCode: string = FACET_CODE.PRICE,
      isConfiguration?: boolean
    ) => applyInputFilter(dispatch, router, baseQuery, queryString, filterCode, !!isConfiguration),
    handleSort: (sort: string) => updateUrlQuery(router, { sort }),
  };
};
