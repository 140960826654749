import useRouter from 'hooks/useRouter';
import Script from 'next/script';
import { BreadcrumbPart } from 'types/Breadcrumb';
import { prefixWithLocale } from 'utils/urlUtil';

const structuredDataListFromBreadcrumb = (crumbs: BreadcrumbPart[], locale: string) => {
  const removeALevel = ('href' in crumbs[0] && !crumbs[0]?.href) ?? true;
  const filteredBreadcrumbs = removeALevel ? crumbs.slice(1) : crumbs;
  const domainName = process.env.NEXT_PUBLIC_SEO_BASE_URL;

  return filteredBreadcrumbs.map((crumb, index) => {
    if ('href' in crumb && crumb?.href) {
      const link = crumb.href.toString();

      return {
        '@type': 'ListItem',
        ...(link && { item: `${domainName}${prefixWithLocale(link, locale)}` }),
        name: crumb.label,
        position: index + 1,
      };
    }
    return {
      '@type': 'ListItem',
      name: crumb.label,
      position: index + 1,
    };
  });
};

type Props = {
  crumbs: BreadcrumbPart[];
};

const BreadcrumbSchemeScript = ({ crumbs }: Props) => {
  const { asPath, locale } = useRouter();

  const itemListElement = structuredDataListFromBreadcrumb(crumbs, locale);

  if (!itemListElement?.length) {
    return null;
  }

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };

  return (
    <Script
      id={`BreadcrumbSchemeScript-${asPath}`}
      dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      type="application/ld+json"
    />
  );
};
export default BreadcrumbSchemeScript;
