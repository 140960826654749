import { NextRouter } from 'hooks/useRouter';
import { Dispatch } from 'redux';
import { Facet } from 'types/Facet';
import { FACET_CODE, FACET_FILTER_DESCRIPTOR } from '../constants/search';
import { searchProducts } from '../redux/modules/search';
import { getLocalizedRoute } from './localizedRouteUtil';
import { scrollTo } from './scrollUtil';
import { pathnames } from 'i18n/pathnames';

const EXCLUDED_PROPERTIES: string[] = ['initialQuery', 'qualifier'];

type SearchObject = Record<string, string>;

/**
 * Function which updates the url query and pushes it
 * @param {instance} router - router instance
 * @param {object} queryObject - object which overwrites the query parameters
 * @returns {undefined}
 */
export const updateUrlQuery = (router: NextRouter, queryObject: SearchObject) => {
  const filteredQueryObject: SearchObject = {};

  Object.keys(queryObject).forEach((key) => {
    if (!EXCLUDED_PROPERTIES.includes(key)) {
      filteredQueryObject[key] = queryObject[key];
    }
  });

  const routerQuery = { ...router.query, currentPage: 0 };

  const localizedPathname = getLocalizedRoute(router.pathname, router.locale, {
    query: { ...routerQuery, ...filteredQueryObject },
  });

  if (localizedPathname) {
    router.replace(localizedPathname, undefined, {
      scroll: false,
      shallow: true,
    });
  } else {
    router.replace(
      {
        pathname: router.pathname,
        query: { ...routerQuery, ...filteredQueryObject },
      },
      undefined,
      { scroll: false, shallow: true }
    );
  }

  scrollTo(undefined, 'search-results-header');
};

export const applySearch = (dispatch: Dispatch, router: NextRouter, searchObject: SearchObject) => {
  const queryValue = typeof searchObject?.q === 'string' ? searchObject?.q : router.query.q;

  if (!queryValue) {
    return null;
  }

  return dispatch(
    // @ts-ignore
    searchProducts(
      queryValue,
      searchObject.currentPage || 0,
      searchObject.pageSize || router.query.pageSize,
      searchObject.sort || router.query.sort,
      searchObject.initialQuery,
      searchObject.facetLimit,
      searchObject.qualifier
    )
  );
};

export const getToRegex = (code: string) => new RegExp(`:${code}:\\[[0-9]*\\s*TO\\s*[0-9]*\\]`, 'g');

export const replaceFilter = (baseQuery: string, queryString: string, filterCode: string, regex: RegExp) =>
  baseQuery.includes(filterCode) ? baseQuery.replace(regex, queryString) : `${baseQuery}${queryString}`;

export const applyFilter = async (
  dispatch: Dispatch,
  router: NextRouter,
  searchObject: SearchObject,
  isConfiguration = false
) => {
  updateUrlQuery(router, searchObject);

  if (!isConfiguration) {
    await applySearch(dispatch, router, searchObject);
  }
};

export const applyInputFilter = async (
  dispatch: Dispatch,
  router: NextRouter,
  baseQuery?: string,
  queryString?: string,
  filterCode = FACET_CODE.PRICE,
  isConfiguration = false
) => {
  const newQuery = replaceFilter(baseQuery ?? '', queryString ?? '', filterCode, getToRegex(filterCode));

  if (!isConfiguration) {
    applyFilter(dispatch, router, { q: newQuery });
  }
};

export const getToFilterValue = (baseQuery: string, filterCode: string, filterValue = 'end') => {
  if (!baseQuery || !filterCode) {
    return null;
  }

  const matchedQuery = baseQuery.match(getToRegex(filterCode));
  const matchedToValue = matchedQuery && matchedQuery[0].match(new RegExp(`[0-9]*\\s*TO\\s*[0-9]*`, 'g'));
  const splitValue = matchedToValue && matchedToValue[0].split('TO');

  if (!splitValue) {
    return null;
  }

  if (filterValue === 'end') {
    return parseInt(splitValue[1]?.trim(), 10);
  }

  if (filterValue === 'start') {
    return parseInt(splitValue[0]?.trim(), 10);
  }

  return null;
};

export const checkIfCurrentPageHasQuery = (router: NextRouter) => {
  const { query: routerQuery, pathname } = router;

  if (!routerQuery) {
    return false;
  }

  if (pathname === pathnames.CASHBACK) {
    return !!Object.keys(routerQuery)?.length;
  }

  const q = routerQuery?.q;
  const query = Array.isArray(q) ? q[0] : q;

  if (!query) return false;

  // Will check if the current query has :relevance or an empty string, Can be expanded in the future
  const regex = new RegExp('^:relevance$|^s*$');
  return !regex.test(query);
};

export const createGroupedFacets = (facets: Facet[]) => {
  const firstCombinedIndex = facets?.findIndex((facet) => facet.grouped);
  const groupedFacets = {
    code: FACET_FILTER_DESCRIPTOR.GROUPED,
    displayName: 'search_grouped_facet_title',
    facets: facets?.filter((facet) => facet?.grouped),
    type: FACET_FILTER_DESCRIPTOR.GROUPED,
  };
  const nonGroupedFacets = facets?.filter((facet) => !facet?.grouped) ?? [];
  const combinedFacets = [...nonGroupedFacets];

  if (groupedFacets?.facets?.length) {
    combinedFacets.splice(firstCombinedIndex, 0, groupedFacets);
  }

  return combinedFacets;
};
