import { useFilter } from 'hooks/useFilter';
import { useIntl } from 'react-intl';
import { FacetSort } from 'types/Facet';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'ui/Accordion/Accordion';
import { Checkbox } from 'ui/Checkbox/Checkbox';
import { FacetFilterHeader, FacetFilterHeaderTitle } from '../FacetFilterHeader/FacetFilterHeader';

interface FacetSortSelectOptionProps {
  onSortOptionChange: (sortOption: FacetSort) => void;
  sortOption: FacetSort;
}

const FacetSortSelectOption = ({ onSortOptionChange, sortOption }: FacetSortSelectOptionProps) => (
  <div className="flex items-center gap-2">
    <Checkbox
      id={sortOption.code}
      onCheckedChange={() => onSortOptionChange(sortOption)}
      checked={sortOption.selected}
    />
    <label className="cursor-pointer" htmlFor={sortOption.code}>
      {sortOption.name}
    </label>
  </div>
);

interface FacetSortSelectProps {
  sortOptions: FacetSort[];
}

const FacetSortSelect = ({ sortOptions }: FacetSortSelectProps) => {
  const { formatMessage } = useIntl();
  const { handleSort } = useFilter();

  const onSortOptionChange = (sortOption: FacetSort) => {
    handleSort(sortOption.code);
  };

  return (
    <AccordionItem value="sortFacet">
      <AccordionTrigger>
        <FacetFilterHeader>
          <FacetFilterHeaderTitle title={formatMessage({ id: 'search_sorting' })} />
        </FacetFilterHeader>
      </AccordionTrigger>
      <AccordionContent className="flex flex-col gap-3">
        {sortOptions.map((sortFacetOption) => (
          <FacetSortSelectOption
            key={sortFacetOption.code}
            sortOption={sortFacetOption}
            onSortOptionChange={onSortOptionChange}
          />
        ))}
      </AccordionContent>
    </AccordionItem>
  );
};
export default FacetSortSelect;
