import BreadcrumbSchemeScript from 'components/Seo/SeoSchemeScripts/BreadcrumbSchemeScript/BreadcrumbSchemeScript';
import { pathnames } from 'i18n/pathnames';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { BreadcrumbPart } from 'types/Breadcrumb';
import {
  Breadcrumb,
  BreadcrumbBack,
  BreadcrumbItem,
  BreadcrumbLabel,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from 'ui/Breadcrumb/Breadcrumb';

export type BreadcrumbProps = {
  activeLabel?: string;
  backHref?: string;
  className?: string;
  crumbs?: BreadcrumbPart[];
  disableHome?: boolean;
};

const BreadcrumbComponent = ({
  activeLabel,
  backHref = pathnames.INDEX,
  className,
  crumbs = [],
  disableHome,
}: BreadcrumbProps) => {
  const { formatMessage } = useIntl();

  const lastCrumb = crumbs.length ? crumbs[crumbs.length - 1] : undefined;

  return (
    <>
      <BreadcrumbSchemeScript crumbs={[...crumbs, { label: activeLabel ?? '' }]} />
      <Breadcrumb className={className}>
        <BreadcrumbList className="md:hidden">
          <BreadcrumbItem>
            <BreadcrumbBack {...lastCrumb}>
              {lastCrumb?.label ?? formatMessage({ id: 'breadcrumb_home_link' })}
            </BreadcrumbBack>
          </BreadcrumbItem>
        </BreadcrumbList>

        <BreadcrumbList className="hidden md:flex">
          {!disableHome && (
            <>
              <BreadcrumbItem>
                <BreadcrumbBack href={backHref}>{formatMessage({ id: 'breadcrumb_home_link' })}</BreadcrumbBack>
              </BreadcrumbItem>

              <BreadcrumbSeparator icon={{ name: 'pipe', styling: 'far' }} />
            </>
          )}

          {crumbs.map((crumb, index) => (
            <Fragment key={`breadcrumb-${index}-${crumb.label}`}>
              <BreadcrumbItem>
                <BreadcrumbLink {...crumb}>{crumb.label}</BreadcrumbLink>
              </BreadcrumbItem>

              {(index < crumbs.length - 1 || activeLabel) && <BreadcrumbSeparator />}
            </Fragment>
          ))}

          {activeLabel && (
            <BreadcrumbItem>
              <BreadcrumbLabel>{activeLabel}</BreadcrumbLabel>
            </BreadcrumbItem>
          )}
        </BreadcrumbList>
      </Breadcrumb>
    </>
  );
};

export default BreadcrumbComponent;
