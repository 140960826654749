import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { BREAKPOINTS } from 'constants/breakpoints';
import { ARROW_POSITIONS } from 'constants/generic';
import { EmblaOptionsType } from 'embla-carousel';
import { useElementViewed } from 'hooks/useElementViewed';
import useRouter from 'hooks/useRouter';
import useTrackingEvents from 'hooks/useTrackingEvents';
import { CMSLeaderBoard } from 'types/CMS';
import { Image } from 'ui/Image/Image';
import { OptionalLink } from 'ui/Link/Link';
import { cn } from 'utils/cn';

const useLeaderboardTracking = (leaderBoard: CMSLeaderBoard) => {
  const { asPath: path, locale } = useRouter();
  const { onComponentClick, onComponentView } = useTrackingEvents();
  const { uid } = leaderBoard;
  const component = 'Leaderboard';
  const campaign = [component, uid, locale].filter(Boolean).join('-');

  const { ref } = useElementViewed(() => {
    onComponentView({
      campaign,
      component,
      path,
    });
  });

  const onClick = () => {
    onComponentClick({
      campaign,
      component,
      path,
    });
  };

  return { onClick, ref };
};

const LeaderboardBanner = (leaderBoard: CMSLeaderBoard) => {
  const { onClick, ref } = useLeaderboardTracking(leaderBoard);
  const {
    backgroundImage,
    backgroundImageMultiBanner,
    linkUrl,
    mobileBackgroundImage,
    mobileBackgroundImageMultiBanner,
    openInNewTab,
    title,
  } = leaderBoard;

  return (
    <OptionalLink
      ref={ref}
      href={linkUrl}
      newTab={openInNewTab}
      onClick={onClick}
      className={cn(
        'relative block aspect-leaderboard-plp-mobile w-full overflow-hidden rounded-lg md:aspect-leaderboard-plp-desktop',
      )}
    >
      <Image
        className="md:hidden"
        alt={title ?? 'leaderboard-banner'}
        quality={100}
        useNext={true}
        nextSizes={{
          default: 500,
        }}
        fill={true}
        src={mobileBackgroundImage?.url ?? mobileBackgroundImageMultiBanner?.url ?? ''}
        style={{
          objectFit: 'contain',
        }}
      />
      {/* Desktop */}
      <Image
        className="hidden md:block"
        alt={title ?? 'leaderboard-banner'}
        quality={100}
        useNext={true}
        fill={true}
        nextSizes={{
          default: 500,
          md: 1000,
        }}
        src={backgroundImage?.url ?? backgroundImageMultiBanner?.url ?? ''}
        style={{
          objectFit: 'contain',
        }}
      />
    </OptionalLink>
  );
};

interface PlpResultsLeaderboardProps {
  className?: string;
  leaderBoards?: CMSLeaderBoard[];
}

const PlpResultsLeaderboard = ({ className, leaderBoards }: PlpResultsLeaderboardProps) => {
  if (!leaderBoards?.length) {
    return null;
  }

  const options: EmblaOptionsType = {
    loop: true,
  };

  return (
    <div className={className}>
      {leaderBoards.length === 1 ? (
        <LeaderboardBanner {...leaderBoards[0]} />
      ) : (
        <SliderEmbla
          options={options}
          slidesToShow={{
            [BREAKPOINTS.XS]: 1,
          }}
          arrowPosition={ARROW_POSITIONS.CENTER}
          showArrows={{ [BREAKPOINTS.MD]: true }}
          showArrowsOnHover={true}
        >
          {leaderBoards.map((leaderBoard, index) => (
            <div key={index}>
              <LeaderboardBanner {...leaderBoard} />
            </div>
          ))}
        </SliderEmbla>
      )}
    </div>
  );
};
export default PlpResultsLeaderboard;
