import { useElementViewed } from 'hooks/useElementViewed';
import useRouter from 'hooks/useRouter';
import useTrackingEvents from 'hooks/useTrackingEvents';
import { CategoryContentTile as CategoryContentTileType } from 'types/Category';
import { Image } from 'ui/Image/Image';
import { OptionalLink } from 'ui/Link/Link';
import { cn } from 'utils/cn';

const useContentTileTracking = (contentTile: CategoryContentTileType) => {
  const { asPath: path, locale } = useRouter();
  const { onComponentClick, onComponentView } = useTrackingEvents();
  const { uid } = contentTile;
  const component = 'ContentTile';
  const campaign = [component, uid, locale].filter(Boolean).join('-');

  const { ref } = useElementViewed(() => {
    onComponentView({
      campaign,
      component,
      path,
    });
  });

  const onClick = () => {
    onComponentClick({
      campaign,
      component,
      path,
    });
  };

  return { onClick, ref };
};

type PlpResultsContentTileProps = {
  className?: string;
  contentTile: CategoryContentTileType;
};

const PlpResultsContentTile = ({ className, contentTile }: PlpResultsContentTileProps) => {
  const { onClick, ref } = useContentTileTracking(contentTile);
  const { backgroundImage, linkUrl, mobileBackgroundImage, openInNewTab, title } = contentTile;
  const backgroundImageUrl = backgroundImage?.url;
  const mobileBackgroundImageUrl = mobileBackgroundImage?.url;

  if (!backgroundImageUrl && !mobileBackgroundImageUrl) {
    return null;
  }

  return (
    <OptionalLink
      ref={ref}
      href={linkUrl}
      newTab={openInNewTab}
      onClick={onClick}
      className={cn(
        'focus-border relative aspect-contenttile-plp-mobile h-full w-full rounded-lg md:mx-auto md:aspect-contenttile-plp-desktop md:w-fit',
        className,
      )}
    >
      {mobileBackgroundImageUrl && (
        <Image
          priority
          alt={title ?? ''}
          useNext
          fill
          nextSizes={{
            default: 500,
          }}
          src={mobileBackgroundImageUrl}
          className="rounded-lg object-contain md:hidden"
        />
      )}
      {backgroundImageUrl && (
        <Image
          priority
          alt={title ?? ''}
          useNext
          nextSizes={{
            default: 300,
          }}
          fill
          src={backgroundImageUrl}
          className="hidden rounded-lg object-contain object-top md:block md:aspect-contenttile-plp-desktop"
        />
      )}
    </OptionalLink>
  );
};

export default PlpResultsContentTile;
