import type { InfoLinks as InfoLinksType } from 'types/Links';
import { TextLink } from 'ui/Link/Link';

type InfoLinksProps = {
  infoLinks: InfoLinksType;
};

const InfoLinks = ({ infoLinks }: InfoLinksProps) => {
  if (!infoLinks.some(({ value: { links } }) => links.length)) return null;
  return (
    <div className="columns-1 gap-3 py-4 lg:columns-3">
      {infoLinks.map(({ key, value: { links } }) => {
        if (!links.length) return null;
        return (
          <div key={key} className="break-inside-avoid">
            <h3>{key.toUpperCase()}</h3>
            <ul>
              {links.map(({ displayName, url }) => (
                <li key={url}>
                  <TextLink href={url} className="text-primary-40 underline">
                    {displayName}
                  </TextLink>
                </li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
};
export default InfoLinks;
