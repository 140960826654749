import { useCategorySilo } from 'features/category/queries';
import { useIntl } from 'react-intl';
import { useFacetStoreActions } from 'store/facets';
import { CategorySimple } from 'types/Category';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'ui/Accordion/Accordion';
import { TextLink } from 'ui/Link/Link';
import { cn } from 'utils/cn';
import { FacetFilterHeader, FacetFilterHeaderTitle } from '../FacetFilterHeader/FacetFilterHeader';

type CategorySiloProps = {
  categorySilo: CategorySimple[];
  currentCategoryCode?: string;
  currentLevel?: number;
  onClick?: () => void;
};

const CategorySilo = ({ categorySilo, currentCategoryCode, currentLevel = 0, onClick }: CategorySiloProps) =>
  categorySilo.map((category) => (
    <div key={category.code} className="flex flex-col gap-1">
      <TextLink
        href={category.url}
        className={cn(currentCategoryCode === category.code ? 'font-bold' : '', 'block')}
        onClick={onClick}
      >
        {category.name}
      </TextLink>
      <div className="flex flex-col gap-1 pl-4">
        {category.subcategoryList && (
          <CategorySilo
            categorySilo={category.subcategoryList}
            currentLevel={currentLevel + 1}
            currentCategoryCode={currentCategoryCode}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  ));

type FacetCategorySiloProps = {
  categoryCode: string;
};

const FacetCategorySilo = ({ categoryCode }: FacetCategorySiloProps) => {
  const { data: siloCategories } = useCategorySilo(categoryCode);
  const { formatMessage } = useIntl();
  const { setMobileFacetsVisible } = useFacetStoreActions();

  if (!siloCategories?.length) return null;

  return (
    <AccordionItem value="categorySilo">
      <AccordionTrigger>
        <FacetFilterHeader>
          <FacetFilterHeaderTitle title={formatMessage({ id: 'silo_navigation_title' })} />
        </FacetFilterHeader>
      </AccordionTrigger>
      <AccordionContent>
        <CategorySilo
          categorySilo={siloCategories}
          currentCategoryCode={categoryCode}
          onClick={() => setMobileFacetsVisible(false)}
        />
      </AccordionContent>
    </AccordionItem>
  );
};
export default FacetCategorySilo;
