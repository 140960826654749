import FacetCategorySilo from 'components/Facets/FacetCategorySilo/FacetCategorySilo';
import FacetFilters from 'components/Facets/FacetFilters/FacetFilters';
import FacetSheet from 'components/Facets/FacetSheet/FacetSheet';
import FacetSortSelect from 'components/Facets/FacetSortSelect/FacetSortSelect';
import { useFacetFilterActions } from 'hooks/useFacetFilterActions';
import { useFacetFilterInfo } from 'hooks/useFacetFilterInfo';
import useToggleURLSearchParams from 'hooks/useToggleURLSearchParams';
import { useFacetStoreActions, useMobileFacetsVisible } from 'store/facets';
import { FacetDefinition, FacetSort } from 'types/Facet';

interface PlpFiltersProps {
  categoryCode?: string;
  className?: string;
  facets: FacetDefinition[];
  sorts?: FacetSort[];
  totalResults: number;
  useQueryParams?: boolean;
}

const PlpFilters = ({
  categoryCode,
  className,
  facets,
  sorts,
  totalResults,
  useQueryParams = false,
}: PlpFiltersProps) => {
  const mobileFacetsVisible = useMobileFacetsVisible();
  const { setMobileFacetsVisible } = useFacetStoreActions();
  const { resetFacetFilters } = useFacetFilterActions();
  const toggleQueryParam = useToggleURLSearchParams();
  const onUpdateUrl = useQueryParams ? toggleQueryParam : undefined;
  const { hasAppliedFacets } = useFacetFilterInfo(facets);
  return (
    <>
      <FacetFilters
        key="facet-filters-desktop"
        className={className}
        facetDefinitions={facets}
        defaultOpenAll={true}
        onUpdateUrl={onUpdateUrl}
      >
        {categoryCode && <FacetCategorySilo key="facets-silo-desktop" categoryCode={categoryCode} />}
      </FacetFilters>
      <FacetSheet
        open={mobileFacetsVisible}
        onOpenChange={setMobileFacetsVisible}
        amountOfResults={totalResults}
        hasAppliedFacetFilters={hasAppliedFacets}
        resetFacetFilters={resetFacetFilters}
      >
        <FacetFilters key="facet-filters-mobile" facetDefinitions={facets} onUpdateUrl={onUpdateUrl}>
          {categoryCode && <FacetCategorySilo key="facets-silo-mobile" categoryCode={categoryCode} />}
          {!!sorts?.length && <FacetSortSelect key="facets-sort-mobile" sortOptions={sorts} />}
        </FacetFilters>
      </FacetSheet>
    </>
  );
};
export default PlpFilters;
